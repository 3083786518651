export default function (errorCode: string): boolean {
  switch (true) {
    case errorCode.startsWith('105'):
      return false
    case errorCode === '110100':
      return false
    case errorCode === '110110':
      return false
    case errorCode === '110200':
      return false
    case errorCode === '110420':
      return false
    case errorCode === '110430':
      return false
    case errorCode.startsWith('120'):
      return false
    default:
      return true
  }
}
